// Vite adds some functionality for import.meta - so we reference the types here:
/// <reference types="vite/client" />

import "@hotwired/turbo-rails";
import { Turbo } from "@hotwired/turbo-rails"

// Default of 500ms is too long, reduce so users can see a direct result of their action
Turbo.setProgressBarDelay(200);
// Turbo.session.drive = false // Disable turbo

// Stimulus controllers
import "~/controllers";

// ActionCable Channels
import "~/channels";
