// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"
import { registerControllers } from "stimulus-vite-helpers";

const controllers = import.meta.glob("./**/*_controller.*", { eager: true });
registerControllers(application, controllers);

import { Toggle } from "tailwindcss-stimulus-components"
application.register('toggle', Toggle)
