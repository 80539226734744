import { Application } from "@hotwired/stimulus"
import debounced from "debounced";

const application = Application.start();
debounced.initialize();

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus   = application

export { application }
