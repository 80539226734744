import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    url: String,
    allowCreate: { type: Boolean, default: false },
    labelField: String,
    allowRemoteSearch: { type: Boolean, default: false },
  }

  connect() {
    let options = {
      plugins: [],
      maxOptions: 200,
    }

    if (this.element.multiple) {
      options.plugins.push("remove_button")
    }

    if (this.hasUrlValue && this.allowRemoteSearchValue) {
      options.load = this.load.bind(this)
    }

    if (this.hasUrlValue && this.allowCreateValue && this.hasLabelFieldValue) {
      options.create = this.createItem.bind(this)
    }

    this.select = new TomSelect(this.element, options)
  }

  disconnect() {
    this.select.destroy()
  }

  async load(query, callback) {
    const response = await get(`${this.urlValue}?query=${query}`)

    if (response.ok) {
      const json = await response.json
      callback(json)
    } else {
      callback()
    }
  }

  async createItem(input, callback) {
    const response = await post(this.urlValue, {
      body: JSON.stringify({ [this.labelFieldValue]: input }),
      responseKind: "json",
    });

    debugger

    if (response.ok) {
      const json = await response.json

      callback({
        value: json.id,
        text: json[this.labelFieldValue],
      })
    } else {
      callback()
    }
  }
}
