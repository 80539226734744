import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="ocr-overlay"
export default class extends Controller {
  static targets = [ "canvas" ]
  static values = {
    data: Array,
    imageUrl: String,
    textBlocksUrl: String,
  }

  async connect() {
    const data = await this.getDataPoints();

    this.loadImage(this.imageUrlValue, image => {
      this.drawOnCanvas(image, data);
    });
  }

  disconnect() {
  }

  loadImage(url, callback) {
    const image = new Image();
    image.onload = () => callback(image);
    image.src = url;
  }

  drawOnCanvas(image, data) {
    const canvas = this.canvasTarget;
    const ctx = canvas.getContext('2d');

    const maxWidth = 800;
    const maxHeight = 800;

    // Resize the image to fit the canvas while keeping the aspect ratio.
    if (image.width > maxWidth || image.height > maxHeight) {
      const widthRatio = maxWidth / image.width;
      const heightRatio = maxHeight / image.height;
      const ratio = Math.min(widthRatio, heightRatio);

      image.width *= ratio;
      image.height *= ratio;

      canvas.width = image.width;
      canvas.height = image.height;
    }

    ctx.drawImage(image, 0, 0, image.width, image.height);

    data.forEach(block => {
      this.drawRectangle(canvas, ctx, block);
    });
  }

  drawRectangle(canvas, ctx, block) {
    const boundingBox = block.bounding_box;

    ctx.lineWidth = 1;

    if (block.block_type === 'WORD') {
      ctx.strokeStyle = 'blue';
    } else if (block.block_type === 'LINE') {
      ctx.strokeStyle = 'green';
    } else {
      ctx.strokeStyle = 'red';
    }

    // The point values are normailized so that they are between 0 and 1.
    // We need to convert them to the actual pixel values.
    ctx.strokeRect(
      boundingBox.left * canvas.width,
      boundingBox.top * canvas.height,
      boundingBox.width * canvas.width,
      boundingBox.height * canvas.height
    );
  }

  async getDataPoints() {
    let dataFromServer = [];

    if (this.textBlocksUrlValue) {
      const response = await get(this.textBlocksUrlValue, {
        responseKind: 'json'
      });

      if (response.ok) {
        dataFromServer = await response.json;
      } else {
        console.error('Failed to fetch data from the server');
      }
    }
    return dataFromServer;
  }
}
